<template>
	<section class="content">
		<div class="modal show" tabindex="-1" role="dialog" ref="formClosingClaimCourier" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content" v-if="tolakClaim == 0">
					<form role="form" @submit.prevent="submitForm">
						<div class="modal-header">
							<h5 class="modal-title">Selesaikan Klaim</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="row">
                                <span class="callout callout-warning">Form ini menandai berakhirnya proses claim atas retur pesanan.</span>
 
								<div class="alert alert-danger" v-if="message.length">{{message}}</div>
							</div>
						</div>
                        <div class="modal-footer">
                            <button type="submit" @click="submitType = 'approve'" :disabled="processing" class="btn btn-success" :hidden="awal == 2">Setujui Claim</button>
                            <button type="button" @click="tolakClaim = '1'" :disabled="processing" class="btn btn-danger" :hidden="awal == 3">Tolak Claim</button>
							<button type="submit" @click="submitType = 'pending'" :disabled="processing" class="btn btn-primary" :hidden="awal == 1">Pending</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
					</form>
				</div>
				<div class="modal-content" v-else>
					<div class="modal-body m-3" v-if="tolakClaim == 1">
						<h4 class="text-center font-weight-bold">Alasan Penolakan</h4>
						<form role="form" @submit.prevent="submitForm">
							<div class="form-group h6">
								<label v-for="(template, index) in templateBatal" class="d-flex align-items-center font-weight-normal" style="gap: 5px">
									<input type="radio" name="delete_reason" :value="template" @change="textBatalChange(index)" v-model="textBatal" /> {{template}}
								</label>
							</div>
							<div class="form-group" v-if="customBatal">
								<label>Ceritakan Alasanmu</label>
								<textarea class="form-control" placeholder="Tuliskan alasan pembatalan" v-model="textBatal"></textarea>
								<small class="d-block text-right mt-1" :class="'text' + colorBatal">Minimal 25 karakter</small>
							</div>
							<div class="d-flex justify-content-between">
								<button type="reset" @click="resetTolak" class="btn btn-lg btn-outline-primary w-49">Batal</button>
								<button type="submit" @click="submitType = 'reject'" class="btn btn-lg btn-primary w-49" :disabled="deleting">Lanjutkan</button>
							</div>
						</form>
					</div>
					<div class="modal-body m-3" v-if="tolakClaim == 2">
						<img src="../assets/img/cancel_order.svg" class="d-block m-auto" />
						<h5 class="text-center font-weight-bold">{{submitType}} berhasil</h5>
						<div class="text-center">Terimakasih!</div>
						<div>&nbsp;</div>
						<button class="btn btn-block btn-lg btn-primary" data-dismiss="modal">Kembali ke riwayat kiriman</button>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { authFetch, auth } from "@/libs/hxcore";
import $ from "jquery";
import Swal from "sweetalert2";

export default {
	name: "FormClosingClaimCourier",
	props: {
		show: Boolean,
		value: Array,
		orderId: String,
		awal: String,
	},
	data() {
		return {
			orderOptions: [],
			errors: [],
			categories: [],
			priorityOpt: [],
			method: "POST",
			selectedId: 0,
			defaultId: 1,
			addrOptions: [],
			addrJne: [],
			courierBranchOption: [],
			formTitle: "Buat Tiket",
			files: [],
			loaded: false,
            message: "",
            submitType: '',
			form: {
				//to create pickup address
				id: "",
				items: [],
				category: "",
				attachments: ""
			},
            processing: false,
			tolakClaim: 0,
			textBatal: '',
			templateBatal: [
				'Seller tidak dapat dihubungi (RTK)',
				'Belum 14 hari stuck (Breach)',
				'Return diterima',
				'Void',
				'Paket terkirim',
				'Tidak ada video unboxing (rusak)',
				'Lainnya',
			],
			customBatal: false,
			colorBatal: 'dark',
		};
	},
	components: {
	},
	created() {
		this.userInfo = auth.user();
	},
	methods: {
		textBatalChange: function (index) {
			if (index == 6) {
				this.textBatal = '';
				this.customBatal = true;
			} else {
				this.textBatal = this.templateBatal[index];
				this.customBatal = false;
				this.colorBatal = 'dark';
			}
		},
		resetTolak: function () {
			this.tolakClaim = 0;
			this.textBatal = '';
			this.customBatal = false;
			this.colorBatal = 'dark';
		},
		submitForm: function (ev) {
	        if (this.submitType != 'banding') {
			} else if (this.customBatal && this.textBatal.length < 25) {
				Swal.fire('Minimal 25 karakter');
	            this.colorBatal = 'danger';
	            return;
	        } else if (this.textBatal == '') {
				Swal.fire('Pilih alasan pembatalan');
	            return;
			}

			this.processing = true;
			var items = [];
            let submitType = this.submitType;
			/*if(this.files.length == 0)
			{
				Swal.fire("Pilih lampiran", "Lampiran tidak boleh kosong", "error");
				return false;
			}*/
			for (var i in this.files) {
				items.push(this.files[i].code);
			}
			if (!this.value.length) return;
			var data = {
                orders: this.value,
                type: submitType,
				reason: this.textBatal
			};

            /* Confirmation Claim */
			const swalConfirmButtons = Swal.mixin({
				customClass: {
				title: 'h4',
				actions: 'd-flex justify-content-between flex-row-reverse',
				cancelButton: 'btn btn-lg btn-outline-primary w-49 font-weight-bold',
				confirmButton: 'btn btn-lg btn-primary w-49 font-weight-bold',
				},
				buttonsStyling: false
			});
            swalConfirmButtons.fire({
                title: `Apakah anda yakin ${submitType} claim?`,
                showCancelButton: true,
                cancelButtonText: "Tidak",
                confirmButtonText: `Ya, ${submitType}`,
            }).then((result) => {
                if (result.isConfirmed) {
                    authFetch("/ticket/claim/closecourier", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(data)
                    })
                    .then(res => {
                        if (res.status === 201) {} else if (res.status === 400) {}
                        return res.json();
                    })
                    .then(js => {
                        this.processing = false;
                        if (js.success) {
                            this.form = {};
                            this.files = [];
                            this.$emit("change", true);
                            $(this.$refs.formClosingClaimCourier).modal("hide");
                            Swal.fire("Proses Berhasil", ``, "success");
                        } else {
                            this.message = js.desc;
                            Swal.fire("Proses gagal", ``, "error");
                        }
                    });
					this.resetTolak();
                } else {
                    this.processing = false;
                }
            });
		},
	},
	mounted() {
		// console.log("warehouse1", this.value);
		const e = this.$refs;
		// if (this.show === true) $(e.formSelectPickup).modal("show");
		// console.log(this.show);
		$(e.formClosingClaimCourier).on("show.bs.modal", e => {
			if (!this.loaded) {
				this.loaded = true;
			}
		});

		$(e.formClosingClaimCourier).on("hide.bs.modal", e => {
			this.message = "";
		});
	},
	watch: {
		show: function (old, val) {
			$(this.$refs.formClosingClaimCourier).modal("show");
		},
		orderId: function (old, val) {
			//this.form.refcode = this.orderId;
		},
		value: function (old, val) {
		},
		awal: function (old, val) {
		},
	}
};
</script>
<style scoped>
</style>